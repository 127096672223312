ul[profile-menu] {
    min-width: 335px;

    li {
        min-height: 35px;

        &:hover {
            background-color: transparent;
            border-bottom: 0 !important;
        }
    }
}