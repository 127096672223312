[nz-button] {
    border-radius: 5px;
}

.ant-btn {
    border-radius: 8px;
}

.ant-btn-text {
    border-color: transparent !important;

    &:hover, &:focus {
        background: transparent !important;
    }
}

@media(max-width: 576px) {
    .ant-btn.big {
        height: 40px;
    }
}

@media(min-width: 577px) {
    .ant-btn.big {
        height: 48px;
    }
}

.ant-btn.special-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    gap: 8px;
    padding: 16px;

    .anticon {
        font-size: 40px;
    }
}
