@import '../utilities/variables';

.section-title,
.page-title {
    color: var(--color-primary-text, $default-primary-text-color);
}

.section-description {
    color: var(--color-secondary-text, $default-secondary-text-color);
}

a, .ant-spin {
    color: var(--color-primary, $default-primary-color);

    &:hover, &:focus, &:active {
        color: var(--color-primary, $default-primary-color);
    }
}

.sub-heading-1, .sub-heading-2 {
    color: var(--color-secondary-text, $default-secondary-text-color);
}

.ant-btn {
    &:hover, &:focus {
        border-color: var(--color-primary, $default-primary-color);
        color: var(--color-primary, $default-primary-color)
    }

    &:active {
        background-color: var(--color-primary, $default-primary-color);
        border-color: var(--color-primary, $default-primary-color);
    }

    color: var(--color-secondary-contrast, $default-secondary-color-contrast);
    border-color: var(--color-secondary, $default-secondary-color);
}

.ant-btn-text {
    &:hover, &:focus {
        border-color: var(--color-primary, $default-primary-color);
        color: var(--color-primary, $default-primary-color)
    }

    &:active {
        background-color: var(--color-primary, $default-primary-color);
        border-color: var(--color-primary, $default-primary-color);
    }

    font-size: 14px;
    color: var(--color-primary, $default-primary-color);
    border-color: var(--color-secondary, $default-secondary-color);
}

li.ant-menu-submenu.ant-menu-submenu-open.ant-menu-submenu-horizontal.ant-menu-submenu-active.ng-star-inserted {
    color: var(--color-primary, $default-primary-color);
    border-color: var(--color-primary, $default-primary-color);
}

ul[profile-menu] {
    li {
        color: var(--color-primary-text, $default-primary-text-color);

        &:hover {
            color: var(--color-primary, $default-primary-color);
        }
    }
}

.menu-list {
    li {
        &:hover {
            color: var(--color-primary, $default-primary-color) !important;
        }
    }
}

.ant-btn-primary {
    background: var(--color-primary, $default-primary-color);
    border-color: var(--color-primary, $default-primary-color);
    color: var(--color-primary-contrast, $default-primary-color-contrast);

    &:hover, &:focus, &:active {
        background: var(--color-primary, $default-primary-color);
        border-color: var(--color-primary, $default-primary-color);
        color: var(--color-primary-contrast, $default-primary-color-contrast);
    }
}

.ant-radio-button, .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child, .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    background: var(--color-primary, $default-primary-color);
    border-color: var(--color-primary, $default-primary-color);
    color: #FFF;

    &:hover, &:focus, &:checked {
        background: var(--color-primary, $default-primary-color);
        border-color: var(--color-primary, $default-primary-color);
    }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #FFF;
}

.ant-radio-button-wrapper:hover {
    color: var(--color-primary, $default-primary-color);
}

li {
    color: var(--color-primary-text, $default-primary-text-color);

    &:hover {
        border-bottom-color: var(--color-primary, $default-primary-color) !important;
    }
}

.ng-bar {
    background-color: var(--color-primary, $default-primary-color) !important;
}

li.ant-menu-item.ant-menu-item-selected {
    color: var(--color-primary, $default-primary-color) !important;
    border-bottom-color: var(--color-primary, $default-primary-color) !important;
}

html {
    --antd-wave-shadow-color: var(--color-primary, $default-primary-color) !important;
}

::selection {
    background: var(--color-primary, $default-primary-color) !important;
}

.ant-input-affix-wrapper {
    &:hover, &:active, &:focus {
        border-color: var(--color-primary, $default-primary-color);
        box-shadow: 0 0 0 1px var(--color-primary, $default-primary-color);
    }
}

.ant-input:hover {
    border-color: var(--color-primary, $default-primary-color);
}

.ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus {
    border-color: var(--color-primary, $default-primary-color);
}

.ant-input:focus, .ant-input-focused {
    border-color: var(--color-primary, $default-primary-color);
    box-shadow: 0 0 0 0px var(--color-primary, $default-primary-color);
}

.ant-input {
    color: var(--color-secondary-text, $default-secondary-text-color);

    &::-webkit-input-placeholder {
        color: var(--color-secondary-text, $default-secondary-text-color);
    }

    &:-ms-input-placeholder {
        color: var(--color-secondary-text, $default-secondary-text-color);
    }

    &::placeholder {
        color: var(--color-secondary-text, $default-secondary-text-color);
        opacity: 0.6;
    }
}

.ant-select:not(.ant-select-customize-input):hover .ant-select-selector{
    border-color: var(--color-primary, $default-primary-color);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-color: var(--color-primary, $default-primary-color);
    box-shadow: 0 0 0 1px var(--color-primary, $default-primary-color);
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-primary, $default-primary-color);
    border-color: var(--color-primary, $default-primary-color);
}

.ant-checkbox-checked::after{
    border-color: var(--color-primary, $default-primary-color);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: var(--color-primary, $default-primary-color) !important;
}

.ant-collapse-header {
    color: var(--color-primary, $default-primary-color);
    font-size: 14px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-primary, $default-primary-color) !important;
}

.ant-tabs-ink-bar {
    background: var(--color-primary, $default-primary-color) !important;
}

.ant-tabs-tab:hover {
    color: var(--color-primary, $default-primary-color);
}

.separator {
    color: var(--color-primary, $default-primary-color) !important;
}

p.price {
    border-bottom-color: var(--color-primary, $default-primary-color) !important;
}

.detail {
    label.ant-radio-wrapper-checked {
        border-color: var(--color-primary, $default-primary-color) !important;
    }
}

.day-selected, day-selected:focus {
    background-color: var(--color-primary, $default-primary-color) !important;
    color: #FFF !important
}

.ant-tag-checkable, .ant-tag-checkable:active {
    background-color: transparent;
    border-color: var(--color-primary, $default-primary-color);
    color: var(--color-primary, $default-primary-color);
}

.ant-tag-checkable-checked {
    background-color: var(--color-primary, $default-primary-color);
    color: #FFF;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: var(--color-primary, $default-primary-color);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: var(--color-primary, $default-primary-color);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: var(--color-primary, $default-primary-color);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: var(--color-primary, $default-primary-color);
}

.ant-picker-today-btn, .ant-picker-today-btn:hover {
    color: var(--color-primary, $default-primary-color);
}

.ant-picker-header-view button {
    &:hover {
        color: var(--color-primary, $default-primary-color);
    }
}

.ant-picker:hover, .ant-picker-focused {
    border-color: var(--color-primary, $default-primary-color);
}

.ant-switch-checked .ant-switch-loading-icon {
    color: var(--color-primary, $default-primary-color);
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: var(--color-primary, $default-primary-color);
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: var(--color-primary, $default-primary-color);
}

.file-area:hover {
    border-color: var(--color-primary, $default-primary-color) !important;
}

.file-icon {
    i {
        color: var(--color-primary, $default-primary-color);
    }
}

.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path,
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: var(--color-primary, $default-primary-color);
}

.featured-product {
    border-color: var(--color-tertiary, $default-tertiary-color) transparent var(--color-tertiary, $default-tertiary-color) var(--color-tertiary, $default-tertiary-color);
}

.fake-carousel-box.selected-carousel {
    background-color: var(--color-primary, $default-primary-color) !important;
}

.map-item {
    &.free {
        border-color: #D9D9D9;
        color: var(--color-primary, $default-primary-color);
    }

    &.selected {
        border-color: var(--color-primary, $default-primary-color);
    }

    &.your {
        border-color: var(--color-primary, $default-primary-color);
        background-color: var(--color-primary, $default-primary-color);
    }

    &.is-disabled, &.booked {
        background-color: #D9D9D9;
        border-color: #D9D9D9;
    }
}

.map-text {
    color: var(--color-primary, $default-primary-color);

    &.selected, &.booked, &.is-disabled {
        color: var(--color-primary-text, $default-primary-text-color);
    }
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: rgba(0, 0, 0, 0.85);
}

.seat-booked {
    border-color: $default-feedback-error-color;
    background-color: $default-feedback-error-color;
    color: #FFF;
}

.caption {
    &.free {
        border-color: var(--color-primary, $default-primary-color);
    }

    &.seat {
        background-color: var(--color-primary, $default-primary-color);
    }

    &.seatBooked {
        background-color: $default-feedback-error-color;
    }

    &.unavailable {
        background-color: #D9D9D9;
    }
}

.ant-switch-checked {
    background-color: var(--color-primary, $default-primary-color);
}

.date-list {
    background-color: #F5F5F5;
    color: var(--color-primary, $default-primary-color);
}

.ant-tag {
    border-color: var(--color-primary, $default-primary-color);
}

.dashboard-section-title {
    color: var(--color-primary-text, $default-primary-text-color);
}

.data-box {
    background-color: #FFFFFF;
    box-shadow: 0 0 12px 0 rgba(0,0,0,0.15);

    .section-text {
        color: #979797;
    }

    &.active {
        border-top-color: #00C800;
    }

    &.canceled {
        border-top-color: #FFA900;
    }

    &.free-trial {
        border-top-color: #08979C;
    }
}

.custom-menu-profile {
    ul {
        background-color: rgba(36, 23, 23, 0.95);

        li {
            color: #FAEFEA9C;
            fill: #FAEFEA9C;

            &:hover {
                color: #FAEFEA !important;
                fill: #FAEFEA;
            }
        }
    }
}

.custom-menu-profile-tonus {
    ul {
        background-color: rgba(0, 0, 0, 0.95);

        li {
            color: #FAEFEA9C;
            fill: #FAEFEA9C;

            &:hover {
                color: #FAEFEA !important;
                fill: #FAEFEA;
            }
        }
    }
}

.section-gray-title {
    border-bottom-color:#E8E8E8;
    background-color: #FAFAFA;
}

.confirm-row {
    border-bottom-color:#E0E0E0;
}

.container-total {
    .title {
        color: var(--color-tertiary-text, $default-tertiary-text-color);
    }

    .price {
        color: #262626;
    }
}

.container-total-next {
    color: #8C8C8C;
}

.data-element {
    color: var(--color-primary-text, $default-primary-text-color)
}

.ant-alert-info {
    background-color: #e6f7ff;
    border-color: #91d5ff;
}

.ant-alert-info .ant-alert-icon {
    color: #1890FF;
}

.fake-carousel {
    .fake-carousel-box {
        background-color: #F0F0F0;
    }
}

.confirm {
    .ant-descriptions-view {
        border-bottom-color: #D9D9D9 !important;
    }
}

.custom-drawer {
    .ant-drawer-content {
        background-color: rgba(36, 23, 23, 0.95);
    }
}

.custom-drawer-tonus {
    .ant-drawer-content {
        background-color: rgba(0, 0, 0, 0.95);
    }
}

.refunded {
    .ant-list-item-meta-title {
        color: $default-feedback-error-color;
    }
}

nz-list[drawer-custom-list] {
    .custom-link {
        border-top-color: rgba(255, 255, 255, 0.2);
        border-bottom-color: rgba(255, 255, 255, 0.2);
    }

    a {
        color: #FAEFEA;
        fill: #FAEFEA;
    }
}

.ant-progress-text {
    color: rgba(0,0,0,0.85);
}

.spin-body {
    .ant-spin-text {
        color: #262626;
    }
}

.page-content, .page-content-register {
    background: #FFF;

    &.gray-content {
        background: $neutral-gray-1;
    }

    .payment-process {
        padding-top: 32px;
    }
}

::-webkit-scrollbar-thumb {
    background-color: var(--color-primary, $default-primary-color);
    outline: 1px solid var(--color-primary, $default-primary-color);
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-primary, $default-primary-color);
}

.loading {
    color: var(--color-primary, $default-primary-color);
}

.primary-link {
    color: var(--color-primary, $default-primary-color);
}

.desc_notifications {
    color: #AAA;
}

.heading-display {
    color: var(--color-secondary, $default-secondary-color);
}

.heading-0, .heading-1, .heading-2, .heading-3, .heading-4, .heading-5 {
    color: var(--color-primary-text, $default-primary-text-color);
}

.common-1, .common-2, .common-3 {
    color: var(--color-primary-text, $default-primary-text-color);
}

.common-label {
    color: var(--color-secondary-text, $default-secondary-text-color);
}

.common-body-label {
    color: var(--color-secondary-text, $default-secondary-text-color);
}

.common-caption-1, .common-caption-2 {
    color: var(--color-secondary-text, $default-secondary-text-color);
}

.common-caption-3 {
    color: var(--color-tertiary-text, $default-tertiary-text-color);
}

.tagline-big {
    color: #ADB5BD;
}

.text-error {
    color: $default-feedback-error-color;
}

nz-select-placeholder {
    color: var(--color-secondary-text, $default-secondary-text-color) !important;
}

.card-integration-input {
    border-color: var(--color-secondary-text, $default-secondary-text-color);
}

nz-select.card-input-select {
    nz-select-item {
        color: var(--color-secondary-text, $default-secondary-text-color);
    }
}

nz-input-group.card-input-group-prefix {
    .ant-input-group-addon {
        background-color: var(--color-primary, $default-primary-color);

        nz-select-item {
            color: var(--color-primary-text, $default-primary-text-color);
        }
    }
}

.seats-row {
    .seat-button {
        &.your {
            border-color: var(--color-primary, $default-primary-color);
            color: var(--color-primary-text, $default-primary-text-color);
            background-color: var(--color-primary, $default-primary-color);
        }
    }
}

.caption-data {
    .circle {
        &.your {
            border-color: var(--color-primary, $default-primary-color);
            background-color: var(--color-primary, $default-primary-color);
        }

        &.selected {
            border-color: var(--color-primary, $default-primary-color);
            background-color: var(--color-primary-contrast, $default-primary-color)
        }
    }
}

.my-avatar-book {
    background-color: var(--color-primary, $default-primary-color);
    color: var(--color-primary-text, $default-primary-text-color);
}

.my-avatar-border {
    border: 2px solid var(--color-primary, $default-primary-color);
}

.my-avatar-guest {
    border-color: var(--color-primary, $default-primary-color);
    color: #262626;
    background-color: #D9D9D9;
}

.no-foto {
    .no-foto-background {
        fill: #F5F5F5;
    }

    .no-foto-circle {
        fill: #D1D1D1;
        fill-opacity: 0.90;
    }
}

.no-foto.avatar-book {
    .no-foto-background {
        fill: var(--color-primary, $default-primary-color);
        fill-opacity: 0.20;
    }

    .no-foto-circle {
        fill: var(--color-primary, $default-primary-color);
    }
}
