.ant-layout-content {
    min-height: calc(100vh - 70px); // header: 64px, footer: 70px
}

.full-width {
    width: 100%;
}

.page-content {
    min-height: calc(100vh - 134px);
    overflow: hidden;

    &.flex {
        display: flex;
        justify-content: center;

        &.center {
            align-items: center;
        }

        &.column {
            flex-direction: column;
        }
    }

    &.with-padding {
        padding-bottom: 64px;
    }
}

.page-content-register {
    min-height: calc(100vh - 400px);
    overflow: hidden;

    &.flex {
        display: flex;
        justify-content: center;

        &.center {
            align-items: center;
        }

        &.column {
            flex-direction: column;
        }
    }
}

@media (max-width: 576px) {
    .ant-layout-content {
        min-height: calc(100vh - 114px); // header: 64px, footer: 114px
    }

    .page-content {
        min-height: calc(100vh - 178px);
    }
}