#double-tab {
    .ant-tabs-content {
        margin-bottom: 8px;
    }

    .ant-tabs-nav-list {
        width: 100%;
    }

    .ant-tabs-tab {
        width: 48%;
        margin: 0 !important;
        text-align: center;
    }

    .ant-tabs-bar {
        margin: 0 !important;
    }

    .ant-tabs-tab-btn {
        width: 98%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.ant-tabs-tab-btn {
    font-size: 16px;
}