nz-list[drawer-list] {
    .ant-list-item {
        text-align: center;
    }

    a, .ant-collapse-header {
        font-size: 17px;
    }
}

nz-list[historical] {
    .ant-list-item-meta-description {
        opacity: .75;
        font-size: 12px;
    }

    .ant-list-item-meta-title {
        font-size: 16px;        
        width: 90%;
        text-align: justify;
    }
}

nz-list[drawer-custom-list] {
    .ant-list-item {
        padding-left: 20px;
    }

    .custom-link {
        height: 61px;
        display: flex;
    }

    a {
        font-size: 14px !important;
        font-weight: 600 !important;
        line-height: 1.2em;
    }
}