@import '../utilities/variables';

.pricing {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.featured-product {
    color: #FFF;
    position: absolute;
    top: 16px;
    left: -8px;
    border-style: solid;
    border-width: 16px 10px 16px 0;
    line-height: 0;
    min-width: 150px;
    padding: 0px 16px;
    text-transform: uppercase;
}

.product-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.product-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 10px;
    white-space: break-spaces;
}

.extended {
    .product-description {
        -webkit-line-clamp: 4;
    }
}

.product-card-footer-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.select-price {
    padding-bottom: 0 !important;
}

.fee-image {
    height: 420px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 576px) {
    .product-description {
        -webkit-line-clamp: 3;
    }
}