.resume-modal {
    .ant-modal-content {
        border-radius: 24px;
        overflow: hidden;
    }

    .ant-modal-body {
        padding: 40px 48px;
    }
}

.upload-modal {
    .ant-modal-body {
        padding: 0;
    }
}

.receipt-payment {
    .ant-modal-body {
        padding: 16px;
    }
}

.loading-modal {
    .ant-modal-content {
        border-radius: 24px;
        overflow: hidden;
    }

    .ant-modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
    }
}

.cybersource-method-modal {
    .ant-modal-body {
        padding: 0;
    }

    iframe {
        width: 100%;
        height: 720px;
        border: 0;
    }
}

.schedule-info-modal {
    .ant-modal-body {
        max-height: 85vh;
        overflow: auto;
    }
}