$default-primary-color: #FF6319;
$default-primary-color-contrast: #FF6319;
$default-secondary-color: #FF6319;
$default-secondary-color-contrast: #FF6319;
$default-tertiary-color: #FF6319;

$default-primary-text-color: #000000;
$default-secondary-text-color: #4A4A4A;
$default-tertiary-text-color: #595959;

$default-feedback-success-color: #1DC663;
$default-feedback-warning-color: #FFAB00;
$default-feedback-info-color: #467FF0;
$default-feedback-error-color: #FF5630;

$default-font-bold: 'Montserrat-Bold';
$default-font-regular: 'Montserrat-Regular';
$default-font-medium: 'Montserrat-Medium';
$default-font-light: 'Montserrat-Light';

$neutral-white: #FFF;

$light-gray: #D4D4D4;
$neutral-gray-1: #F8F9FA;
$neutral-gray-7: #868E96;
$dark-16: #212529
