.ant-collapse.menu-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;

    i {
        padding: 0 !important;
    }
}

.ant-collapse.menu-collapse > .ant-collapse-item > .ant-collapse-header {
   color: #595959 !important;
}
