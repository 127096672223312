@import '../utilities/variables';

.detail {
    .ant-radio {
        display: none;
    }
}

.ant-radio-checked .ant-radio-inner {
    border-color: var(--feedback-success, $default-feedback-success-color);

    &::after {
        background-color: var(--feedback-success, $default-feedback-success-color);
    }
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: var(--feedback-success, $default-feedback-success-color);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: var(--feedback-success, $default-feedback-success-color);
}

.fee-price-radio {
    margin: 8px;
    width: 100px;
}

.big-radio {
    margin-right: 0;

    .ant-radio-inner {
        width: 24px;
        height: 24px;

        &::after {
            transform: scale(1.1);
        }
    }
}

.vertical-radio {
    [nz-radio] {
        display: block;
    }
}

.reversed-radio {
    width: 100%;

    [nz-radio] {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        padding: 8px 0;
        border-bottom: 1px solid #F0F0F0;

        &::after {
            content: none;
        }
    }
}
