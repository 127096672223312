@import '../utilities/variables';

.ant-steps-item-icon {
    margin: 0;
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
    width: 40px;
    height: 40px;
    line-height: 38px;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: var(--color-tertiary, $default-tertiary-color);
    border-color: var(--color-tertiary, $default-tertiary-color);
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
    border-color: var(--color-tertiary, $default-tertiary-color);
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: var(--color-tertiary, $default-tertiary-color);
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
    background-color: var(--color-tertiary, $default-tertiary-color);
}

.ant-steps-item-wait .ant-steps-item-icon {
    border-color: $neutral-gray-7;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: $neutral-gray-7;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: $neutral-gray-7;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: var(--color-tertiary, $default-tertiary-color);
}