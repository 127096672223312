@import '../utilities/variables';

/*
    --font-family-bold
    --font-family-regular
    --font-family-medium
    --font-family-light
*/

[family-bold],
.section-title,
.page-title,
.error-title,
.error-subtitle,
.dashboard-section-title,
.title-header h1,
.ant-modal-title,
.ant-result-title,
.ant-modal-confirm-title {
    font-family: var(--font-family-bold, $default-font-bold), sans-serif;
}

[family-regular],
a,
.section-description,
.show-more,
.section-gray-title,
.data-element,
.ant-popover-message-title,
.ant-result-subtitle,
.ant-modal-confirm-content {
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

[family-light],
.error-description,
img,
.ant-tag {
    font-family: var(--font-family-light, $default-font-light), sans-serif;
}

[family-medium],
.ant-collapse-header,
.confirm-row,
.container-total,
.container-total-next {
    font-family: var(--font-family-medium, $default-font-medium), sans-serif;
}

a, .ant-spin {
    &.turn-back {
        font-family: var(--font-family-medium, $default-font-medium), sans-serif;
    }
}

[nz-button] {
    font-family: var(--font-family-bold, $default-font-bold), sans-serif;
}

.featured-product {
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

.primary-link {
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

.data-box {
    .section-text {
        font-family: var(--font-family-bold, $default-font-bold), sans-serif;
    }
}

nz-list[drawer-list] {
    a, .ant-collapse-header {
        font-family: var(--font-family-medium, $default-font-medium), sans-serif;
    }
}

nz-list[historical] {
    .ant-list-item-meta-description {
        font-family: var(--font-family-regular, $default-font-regular), sans-serif;
    }

    .ant-list-item-meta-title {
        font-family: var(--font-family-medium, $default-font-medium), sans-serif;
    }
}

.ant-progress-text {
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

.spin-body {
    .ant-spin-text {
        font-family: var(--font-family-regular, $default-font-regular), sans-serif;
    }
}

.ant-tabs-tab-btn {
    font-family: var(--font-family-medium, $default-font-medium), sans-serif;
}

.ant-layout-footer {
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

.ant-badge-status-text {
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

.ant-tooltip-inner {
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

.ant-form-item-explain-error {
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

input {
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

.radio-button {
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

.ant-select-item-option-content {
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

.ant-alert-message {
    font-family: var(--font-family-medium, $default-font-medium), sans-serif;
}

.ant-alert-description {
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

nz-select {
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

.heading-display {
    font-size: 72px;
    font-family: var(--font-family-bold, $default-font-bold), sans-serif;
}

.heading-0, .heading-1, .heading-2, .heading-3, .heading-4 {
    font-family: var(--font-family-bold, $default-font-bold), sans-serif;
}

.heading-0 {
    font-size: 56px;
    line-height: 64px;
}

.heading-1 {
    font-size: 48px;
    line-height: 56px;
}

.heading-2 {
    font-size: 40px;
    line-height: 48px;
}

.heading-3 {
    font-size: 32px;
    line-height: 40px;
}

.heading-4, .tagline-big {
    font-size: 24px;
    line-height: 24px;
}

.heading-5 {
    font-size: 12px;
    font-weight: 700;
}

.sub-heading-1 {
    font-size: 24px;
    line-height: 24px;
    font-family: var(--font-family-bold, $default-font-bold), sans-serif;
}

.sub-heading-2 {
    font-size: 18px;
    line-height: 27px;
    font-family: var(--font-family-bold, $default-font-bold), sans-serif;
}

.common-1 {
    font-size: 16px;
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

.common-2 {
    font-size: 14px;
    font-family: var(--font-family-bold, $default-font-bold), sans-serif;
}

.common-3 {
    font-size: 14px;
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

.common-4 {
    font-size: 20px;
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

.common-5 {
    font-size: 24px;
    font-family: var(--font-family-bold, $default-font-bold), sans-serif;
}

.common-6 {
    font-size: 12px;
    font-family: var(--font-family-bold, $default-font-bold), sans-serif;
}

.common-7 {
    font-size: 12px;
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

.common-label {
    font-size: 16px;
    display: flex;
    gap: 8px;
    font-family: var(--font-family-bold, $default-font-bold), sans-serif;

    &.required::after {
        content: " *";
    }
}

.common-body-label {
    font-size: 16px;
    font-family: var(--font-family-bold, $default-font-bold), sans-serif;
}

.common-caption-1 {
    font-size: 14px;
    line-height: 21px;
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

.common-caption-2 {
    font-size: 14px;
    line-height: 21px;
    font-family: var(--font-family-bold, $default-font-bold), sans-serif;
}

.common-caption-3 {
    font-size: 12px;
    line-height: 18px;
    font-family: var(--font-family-bold, $default-font-bold), sans-serif;
}

nz-input-group.card-input-group-prefix {
    .ant-input-group-addon {
        nz-select-item {
            font-size: 16px;
            font-family: var(--font-family-bold, $default-font-bold), sans-serif;
        }
    }
}

.receipt-text-1 {
    font-size: 16px;
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;
}

@media(max-width: 576px) {
    .heading-0 {
        font-size: 48px;
    }

    .heading-1 {
        font-size: 40px;
    }

    .heading-2 {
        font-size: 32px;
    }

    .heading-3 {
        font-size: 24px;
        line-height: 32px;
    }

    .heading-4 {
        font-size: 16px;
        line-height: 24px;
    }

    .sub-heading-1 {
        font-size: 20px;
    }

    .sub-heading-2 {
        font-size: 14px;
    }

    .common-label {
        font-size: 14px;
    }

    .common-body-label {
        font-size: 14px;
    }
}
