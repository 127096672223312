@import '../utilities/variables';

.primary-icon-24 {
    font-size: 24px;
    color: var(--color-primary, $default-primary-color);
}

.secondary-icon-48 {
    font-size: 48px;
    color: var(--color-secondary-text, $default-secondary-text-color);
}

.danger-icon-24 {
    font-size: 24px;
    color: $default-feedback-error-color;
}

.anticon.ant-notification-notice-icon-info {
    color: $default-feedback-info-color;
}