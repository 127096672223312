@font-face {font-family: 'Montserrat-Bold';src: url('/assets/fonts/Montserrat-Bold.ttf') format('truetype');}
@font-face {font-family: 'Montserrat-Light';src: url('/assets/fonts/Montserrat-Light.ttf') format('truetype');}
@font-face {font-family: 'Montserrat-Regular';src: url('/assets/fonts/Montserrat-Regular.ttf') format('truetype');}
@font-face {font-family: 'Montserrat-Medium';src: url('/assets/fonts/Montserrat-Medium.ttf') format('truetype');}

@font-face {font-family: 'Visby-Bold';src: url('/assets/fonts/vidya/VisbyCF-Bold.otf') format('truetype');}
@font-face {font-family: 'Visby-Light';src: url('/assets/fonts/vidya/VisbyCF-Light.otf') format('truetype');}
@font-face {font-family: 'Visby-Regular';src: url('/assets/fonts/vidya/VisbyCF-Regular.otf') format('truetype');}
@font-face {font-family: 'Visby-Medium';src: url('/assets/fonts/vidya/VisbyCF-Medium.otf') format('truetype');}

@font-face {font-family: 'Gotham-Bold';src: url('/assets/fonts/tonus/Gotham-Bold.otf') format('truetype');}
@font-face {font-family: 'Gotham-Light';src: url('/assets/fonts/tonus/Gotham-Light.otf') format('truetype');}
@font-face {font-family: 'Gotham-Regular';src: url('/assets/fonts/tonus/Gotham-Regular.otf') format('truetype');}
@font-face {font-family: 'Gotham-Medium';src: url('/assets/fonts/tonus/Gotham-Medium.otf') format('truetype');}
