@import '../utilities/variables';

.ant-card-bordered {
    border-radius: 8px;

    &.primary {
        border-color: var(--color-primary, $default-primary-color);
    }

    &.secondary {
        border-color: var(--color-secondary, $default-secondary-color);
    }
}

.no-border-header {
    .ant-card-head {
        border: 0;
    }
}

.payment-method-card-2 {
    .ant-card-head-title {
        white-space: unset;
    }
}

.product-card {
    background-color: #FFFFFF;

    .ant-card-body {
        position: relative;
        width: 360px;
        height: 442px;
        padding: 32px;
        padding-top: 56px;
        max-width: 90vw;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &::after, &::before {
            content: none;
        }
    }

    &.extended {
        .ant-card-body {
            height: 525px;
        }
    }
}

.card-1 {
    .ant-card-head {
        padding: 0 32px;
    }

    .ant-card-body {
        padding: 0 32px 32px;
    }

    .ant-card-head-title {
        padding: 32px 0 16px;
    }
}

.card-2 {
    border-radius: 16px;
    max-width: 185px;
    .ant-card-body {
        display: flex;
        min-height: 300px;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px;
        gap: 8px;

        &::after, &::before {
            content: none;
        }
    }
}

.card-3 {
    border-radius: 24px;
    background-color: $light-gray;
}

.card-gray {
    background-color: #FAFAFA;
}

.card-gray-2 {
    background-color: #F0F0F0;
}

.card-4 {
    .ant-card-body {
        padding: 24px;
    }
}

.card-5 {
    max-width: 180px;
    border-radius: 16px;
    .ant-card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px 12px;
        gap: 8px;

        &::after, &::before {
            content: none;
        }
    }
}

.payment-method-card, .payment-method-card-add {
    border: 2px solid;
    height: 184px;
    padding: 32px;

    .ant-card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        height: 100%;

        &::after, &::before {
            content: none;
        }
    }

    img {
        width: 56px;
    }
}

.payment-method-add {
    padding: 48px;
}

@media(max-width: 576px) {
    .payment-method-card {
        height: 264px;
    }

    .payment-method-add {
        padding: 32px 16px 48px;
    }

    .card-2 {
        max-width: 300px;
    }
}

@media(min-width: 577px) and (max-width: 1600px) {
    .card-2 {
        max-width: 250px;
    }
}
