nz-content {
    nz-spin {
        height: 100%;

        .ant-spin-container {
            height: 100%;
        }
    }
}

.ant-spin-nested-loading > div > .ant-spin {
    padding: 100px 0;
}

.nopadding-spin {
    .ant-spin {
        padding: 0 !important;
    }
}

.total-spin {
    .ant-spin.ant-spin-spinning {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: inherit;
        min-height: calc(100vh - 134px);
        max-height: fit-content;
    }
}

.spin-body {
    .ant-spin-text {
        font-weight: 400;
        font-size: 14px;
    }
}

.custom-spin {
    .ant-spin {        
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 0 !important;
        height: 160px !important;
    }
}