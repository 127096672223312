.custom-collapse {
    .ant-collapse-header {
        color: #595959 !important;
        font-family: var(--font-family-regular, $default-font-regular), sans-serif;
        font-weight: 500;
    }
    .ant-collapse-content {
        color:#595959 !important;
        font-family: var(--font-family-regular, $default-font-regular), sans-serif;
        font-weight: 400;
    }
}

.seats-row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(var(--number-columns, 5), minmax(40px, 1fr));
    grid-row-gap: 1em;
    grid-column-gap: .5em;
    overflow: auto;
    padding-bottom: 8px;
    padding-top: 8px;

    .seat-button {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-self: center;
        font-family: var(--font-family-regular, $default-font-regular), sans-serif;

        &.free {
            border-color: #BFBFBF;
            color: #BFBFBF;
        }

        &.your {
            border-color: #8C8C8C;
            background-color: #BFBFBF;
        }

        &.unavailable {
            border-color: #D9D9D9;
            background-color: #D9D9D9;
        }

        &.selected {
            border-color: var(--color-primary, $default-primary-color);
            background-color: var(--color-primary-contrast, $default-primary-color-contrast);
            color: var(--color-primary, $default-primary-color);
        }
    }

    app-public-profile-avatar {
        justify-self: center;
    }
}

.caption-data {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #595959;
    margin-left: 16px;
    font-family: var(--font-family-regular, $default-font-regular), sans-serif;

    .circle {
        width: 16px;
        height: 16px;
        border: 1px solid;
        border-radius: 50%;

        &.free {
            border-color: #BFBFBF;
        }

        &.unavailable {
            border-color: #D9D9D9;
            background-color: #D9D9D9;
        }
    }
}

.public_profile_container {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;

    .public_avatar {
        &.info nz-avatar {
            cursor: pointer;
        }
        padding: 4px;

        &.selected {
            background-color: #FFE8D9;
            border-radius: 8px;
        }
    }
}

.selector-filter-activities {
    .ant-select-selector {
        height: 40px;
        border-radius: 8px !important;
    }
}

.border-radius-common {
    border-radius: 8px;
}

