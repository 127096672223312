.ant-drawer-body {
    padding-left: 0;
    padding-right: 0;
}

.header-payments {
    .ant-drawer-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
    }
}

.custom-drawer {
    .ant-drawer-body {
        padding: 0;
    }
}

.custom-drawer-tonus {
    .ant-drawer-body {
        padding: 0;
    }
}