@media print {
    body * {
        visibility: hidden;
    }
    #printable, #printable * {
        visibility: visible;
    }
    .ant-modal {
        top: 0;
        width: auto;
        margin-left: 0;
    }

    button {
        display: none !important;
    }

    .hidden-date {
        display: block !important;
    }
}