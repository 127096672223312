.access-pages {
    padding: 40px 0;
    background-size: cover !important;
    background-position: center !important;
}

.double-row {
    width: 100%;
    display: flex;
    justify-content: center;
}

.double-image {
    background-size: cover;
    border-radius: 24px
}

@media(max-width: 576px) {
    .double-row {
        padding: 0 8px;
    }
}

@media(min-width: 577px) {
    .double-row {
        padding: 0 40px;
    }
}

@media(max-width: 992px) {
    .double-image {
        display: none;
    }

    .double-data {
        max-width: 100%;
    }
}

@media(min-width: 993px) {
    .double-row {
        padding: 0 80px;
        gap: 40px;
    
        .double-image, .double-data {
            flex: 1;
            max-width: 50%;
        }
    }

    .access-pages {
        background: $neutral-gray-1 !important;
    }
}