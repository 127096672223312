@charset "UTF-8";
.section-title {
  font-size: 35px;
  line-height: 43px;
}

.section-description {
  font-size: 18px;
  line-height: 30px;
  white-space: pre-wrap;
}

.page-title {
  text-align: center;
  padding: 32px;
  font-size: 30px;
  line-height: 43px;
  margin-bottom: 0;
}

/*
* ERRORS
*/
.error-background {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #FFF;
}

.error-body {
  width: 400px;
}

.error-title {
  color: #A2A2A2;
  font-size: 50px;
  font-weight: bold;
  line-height: 61px;
  text-align: center;
}

.error-subtitle {
  color: #A2A2A2;
  font-size: 25px;
  line-height: 25px;
  text-align: center;
  text-transform: none;
  margin-top: 24px;
}

.error-description {
  color: #A2A2A2;
  font-size: 17px;
  font-weight: 300;
  line-height: 25px;
  text-align: center;
  text-transform: none;
}

html, body {
  scroll-padding-top: 64px;
}

a.turn-back, .ant-spin.turn-back {
  font-size: 15px;
}

li:hover {
  border-bottom: 2px solid !important;
}

li.ant-menu-item.ant-menu-item-selected {
  border-bottom: 2px solid !important;
}

.ant-collapse-header {
  font-size: 14px;
}

.separator {
  width: 100%;
  border-bottom: solid 2px;
  position: relative;
  margin: 24px 0px 24px;
}

p.price {
  border-bottom: 3px solid !important;
}

.fake-carousel-box.selected-carousel {
  width: 24px !important;
}

.map-item {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2px;
  cursor: pointer;
  border: 1px solid;
}
.map-item img {
  width: 20px !important;
}
.map-item.is-disabled, .map-item.booked {
  cursor: inherit;
}

.map-text {
  font-size: 14px;
  text-align: center;
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  text-align: center;
  padding: 16px;
}

.ant-upload {
  padding: 8px;
}

.caption.free {
  border: 1px solid;
}

.date-list {
  display: flex;
  justify-content: center;
  padding: 16px !important;
  margin-top: 16px;
}

@media (max-width: 992px) {
  .section-title {
    font-size: 30px;
  }
}
div[text-center] {
  text-align: center;
}

div[text-right] {
  text-align: right;
}

.text-align-right {
  text-align: right;
}

div[top-0] {
  margin-top: 0 !important;
}

div[top-8], button[top-8] {
  margin-top: 8px !important;
}

div[top-16], button[top-16] {
  margin-top: 16px !important;
}

div[top-24], nz-card[top-24] {
  margin-top: 24px !important;
}

div[top-32], button[top-32], nz-card[top-32] {
  margin-top: 32px !important;
}

div[top-40], nz-card[top-40] {
  margin-top: 40px !important;
}

div[top-48] {
  margin-top: 48px;
}

div[top-64] {
  margin-top: 64px;
}

div[bottom-16] {
  margin-bottom: 16px;
}

div[bottom-24] {
  margin-bottom: 24px;
}

div[bottom-48] {
  margin-bottom: 48px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-left-8 {
  margin-left: 8px;
}

.margin-left-16 {
  margin-left: 16px;
}

.margin-right-8 {
  margin-right: 8px;
}

.margin-right-16 {
  margin-left: 16px;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.vertical-padding {
  padding-top: 16px;
  padding-bottom: 16px;
}

nz-date-picker#fake-calendar {
  padding: 0;
  border: 0;
  box-shadow: none;
}

#fake-calendar .ant-picker-input.ng-star-inserted, #fake-calendar input {
  display: none;
}

.anticon-spin {
  animation: loadingCircle 0.5s infinite linear;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
   supported by Chrome, Edge, Opera and Firefox */
}

/* TODO: Añadir más colores custom según se vaya viendo. */
.grecaptcha-badge {
  display: none;
}

.dashboard-section-title {
  font-size: 30px;
  margin-bottom: 0;
  font-weight: bold;
}

.data-box {
  min-height: 225px;
}
.data-box .section-text {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #979797;
  text-align: center;
}
.data-box .section-description {
  padding: 16px;
  display: flex;
  align-items: center;
}
.data-box .section-progress {
  display: flex;
  align-items: center;
}
.data-box .section-progress-mobile {
  display: flex;
  justify-content: center;
}
.data-box .section-status {
  padding: 16px;
  text-align: right;
}
.data-box .section-button {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
}
.data-box .section-button button {
  margin-top: 16px;
}
.data-box.active {
  border-top: 8px solid;
}
.data-box.canceled {
  border-top: 8px solid;
}
.data-box.free-trial {
  border-top: 8px solid;
}

.margin-top-16 {
  margin-top: 16px !important;
}

.show-more {
  font-size: 18px;
  text-decoration: underline;
}

.custom-menu-profile {
  margin-top: 16px;
}
.custom-menu-profile ul li {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2em;
}
.custom-menu-profile ul li:hover {
  border-bottom: 0 !important;
}

.custom-menu-profile-tonus {
  margin-top: 16px;
}
.custom-menu-profile-tonus ul li {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2em;
}
.custom-menu-profile-tonus ul li:hover {
  border-bottom: 0 !important;
}

.section-gray-title {
  padding: 16px;
  border-bottom: 2px solid;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}
.section-gray-title.gutter {
  padding: 16px 8px;
}

.confirm-row {
  padding: 16px;
  border-bottom: 1px solid;
}

.info-row {
  padding: 16px;
}

.total-inputs {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
}

.container-total {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  text-align: right;
}
.container-total .title {
  font-size: 14px;
  flex: 2;
}
.container-total .price {
  font-size: 20px;
  font-weight: 600;
  width: 120px;
}

.container-total-next {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  text-align: right;
}
.container-total-next .title {
  font-size: 12px;
  flex: 2;
}
.container-total-next .price {
  font-size: 14px;
  font-weight: 600;
  width: 120px;
  text-align: right;
}

.payment-method {
  display: flex;
  align-items: center;
  gap: 8px;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.data-element {
  border: 0;
  box-shadow: none;
  padding: 4px 0;
}
.data-element .ant-select-selector {
  border: 0 !important;
  padding: 0 !important;
}

.title-header h1 {
  font-size: 30px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.sepa-info {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 700;
}

.ant-result-title {
  line-height: 26px;
}

.ant-result-subtitle {
  margin-top: 16px;
}

.custom-loader {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--color-primary, #FF6319) 94%, rgba(0, 0, 0, 0)) top/16px 16px no-repeat, conic-gradient(rgba(0, 0, 0, 0) -15%, var(--color-primary, #FF6319));
  -webkit-mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 16px), #000 0);
  mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 16px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}
.adding-coupon input, .adding-coupon button {
  height: 56px;
  font-size: 16px;
}

.payment-method-data {
  display: flex;
  align-items: center;
  gap: 8px;
}

.item-options, .item-start-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

input.card-input, .ant-input-number.card-input {
  height: 56px;
  font-size: 16px;
  border-radius: 8px;
  margin-top: 16px;
  width: 100%;
  border-color: var(--color-secondary, #FF6319);
}
input.card-input input, .ant-input-number.card-input input {
  height: 56px;
  color: var(--color-secondary-text, #4A4A4A);
}

textarea.card-textarea {
  font-size: 16px;
  border-radius: 8px;
  margin-top: 16px;
  width: 100%;
  border-color: var(--color-secondary, #FF6319);
}
textarea.card-textarea input {
  height: 56px;
  color: var(--color-secondary-text, #4A4A4A);
}

.card-integration-input {
  border: 0;
  border-bottom: 1px solid;
  box-shadow: none;
  padding: 4px 16px;
  margin-top: 16px;
}
.card-integration-input .ant-select-selector {
  border: 0 !important;
  padding: 0 !important;
}

nz-input-group.card-input-group, nz-date-picker.card-input-date {
  height: 56px;
  border-radius: 8px;
  margin-top: 16px;
  width: 100%;
  border-color: var(--color-secondary, #FF6319);
}
nz-input-group.card-input-group input, nz-date-picker.card-input-date input {
  font-size: 16px;
}
nz-input-group.card-input-group .anticon, nz-date-picker.card-input-date .anticon {
  color: var(--color-secondary-text, #4A4A4A);
}

nz-input-group.card-input-group-prefix {
  height: 56px;
  border-radius: 8px;
  margin-top: 16px;
  width: 100%;
}
nz-input-group.card-input-group-prefix .ant-input-group-addon {
  height: 56px;
  border-radius: 8px 0 0 8px;
}
nz-input-group.card-input-group-prefix input {
  font-size: 16px;
  height: 56px;
  border-radius: 0 8px 8px 0;
}

nz-select.card-input-select {
  width: 100%;
  height: 56px;
  border-radius: 8px;
  margin-top: 16px;
}
nz-select.card-input-select nz-select-top-control {
  border-radius: 8px !important;
  height: 56px !important;
  border-color: var(--color-secondary, #FF6319) !important;
}
nz-select.card-input-select nz-select-item, nz-select.card-input-select nz-select-placeholder {
  padding-top: 12px !important;
  font-size: 16px;
}
nz-select.card-input-select input {
  font-size: 16px;
}

.gray-box {
  height: 32px;
  width: 42px;
  background: #ADB5BD;
}

.horizontal-field {
  display: flex;
  align-items: center;
  gap: 8px;
}
.horizontal-field .card-integration-input {
  width: 100%;
  margin-top: 0;
}
.horizontal-field #card-expiry {
  min-width: 85px;
}
.horizontal-field #card-cvc {
  min-width: 65px;
}

.border-bottom-dark-16 {
  border-bottom: 1px solid rgba(33, 37, 41, 0.16);
}

.failed-icon {
  border: 3px solid #FF5630;
  padding: 8px;
  border-radius: 50%;
}

.booking-icon-with-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 576px) {
  .item-options {
    justify-content: center;
  }
  .item-start-options {
    justify-content: start;
  }
  .horizontal-field {
    gap: 4px;
  }
  .horizontal-field .card-integration-input {
    padding: 4px 8px;
  }
  .horizontal-field #card-expiry {
    min-width: 70px;
  }
  .horizontal-field #card-cvc {
    min-width: 50px;
  }
  .mobile-full {
    width: 100%;
  }
  div[top-24-to-16] {
    margin-top: 16px !important;
  }
}
@media (min-width: 577px) {
  div[top-24-to-16] {
    margin-top: 24px !important;
  }
}
.ant-affix {
  padding: 16px 0;
  background-color: #F8F9FA;
}

body {
  top: 0 !important;
}

.skiptranslate {
  display: none !important;
}

.goog-te-gadget {
  display: var(--show-translations, "none") !important;
  color: transparent !important;
}
.goog-te-gadget > div {
  color: #666 !important;
  background-color: #dbdad9;
  padding: 8px;
}
.goog-te-gadget > span {
  display: none;
}

.gtrans {
  position: absolute;
  top: 64px;
  right: 0;
  background-color: transparent;
  border-radius: 4px;
  text-align: center;
}

.selector-transparent .ant-select-selector {
  background-color: transparent !important;
}

.ant-select-arrow {
  color: #595959;
}

.flex {
  display: flex;
  align-items: center;
}
.flex.gap-8 {
  gap: 8px;
}
.flex.justify {
  justify-content: center;
}

.map-grid {
  padding: 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-warning-icon {
  font-size: 21px;
  color: #FAAD14;
}

.map-body {
  max-height: var(--map-max-height, 380px);
  display: grid;
  grid-template-columns: repeat(var(--number-columns-map, 5), auto);
  width: min-content;
}
.map-body.desktop {
  height: 240px;
}

.cell {
  width: var(--map-x-size, 54px);
  height: var(--map-y-size, 76px);
}

.no-wrap {
  flex-wrap: nowrap;
}

.no-foto {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("/assets/fonts/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Light";
  src: url("/assets/fonts/Montserrat-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("/assets/fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("/assets/fonts/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Visby-Bold";
  src: url("/assets/fonts/vidya/VisbyCF-Bold.otf") format("truetype");
}
@font-face {
  font-family: "Visby-Light";
  src: url("/assets/fonts/vidya/VisbyCF-Light.otf") format("truetype");
}
@font-face {
  font-family: "Visby-Regular";
  src: url("/assets/fonts/vidya/VisbyCF-Regular.otf") format("truetype");
}
@font-face {
  font-family: "Visby-Medium";
  src: url("/assets/fonts/vidya/VisbyCF-Medium.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-Bold";
  src: url("/assets/fonts/tonus/Gotham-Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-Light";
  src: url("/assets/fonts/tonus/Gotham-Light.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-Regular";
  src: url("/assets/fonts/tonus/Gotham-Regular.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-Medium";
  src: url("/assets/fonts/tonus/Gotham-Medium.otf") format("truetype");
}
.primary-color {
  color: var(--color-primary, #FF6319);
}

span.field-label.required::before, label.field-label.required::before {
  color: #FF5630;
}

span.field-label, label.field-label {
  opacity: 0.8;
  font-weight: 400;
  font-size: 14px;
}
span.field-label.required::before, label.field-label.required::before {
  content: "* ";
}

.ant-alert {
  border-radius: 8px;
}

.ant-avatar-group {
  display: contents;
}

.ant-avatar {
  margin-right: 24px;
}

[nz-button] {
  border-radius: 5px;
}

.ant-btn {
  border-radius: 8px;
}

.ant-btn-text {
  border-color: transparent !important;
}
.ant-btn-text:hover, .ant-btn-text:focus {
  background: transparent !important;
}

@media (max-width: 576px) {
  .ant-btn.big {
    height: 40px;
  }
}
@media (min-width: 577px) {
  .ant-btn.big {
    height: 48px;
  }
}
.ant-btn.special-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  gap: 8px;
  padding: 16px;
}
.ant-btn.special-btn .anticon {
  font-size: 40px;
}

.ant-card-bordered {
  border-radius: 8px;
}
.ant-card-bordered.primary {
  border-color: var(--color-primary, #FF6319);
}
.ant-card-bordered.secondary {
  border-color: var(--color-secondary, #FF6319);
}

.no-border-header .ant-card-head {
  border: 0;
}

.payment-method-card-2 .ant-card-head-title {
  white-space: unset;
}

.product-card {
  background-color: #FFFFFF;
}
.product-card .ant-card-body {
  position: relative;
  width: 360px;
  height: 442px;
  padding: 32px;
  padding-top: 56px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.product-card .ant-card-body::after, .product-card .ant-card-body::before {
  content: none;
}
.product-card.extended .ant-card-body {
  height: 525px;
}

.card-1 .ant-card-head {
  padding: 0 32px;
}
.card-1 .ant-card-body {
  padding: 0 32px 32px;
}
.card-1 .ant-card-head-title {
  padding: 32px 0 16px;
}

.card-2 {
  border-radius: 16px;
  max-width: 185px;
}
.card-2 .ant-card-body {
  display: flex;
  min-height: 300px;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  gap: 8px;
}
.card-2 .ant-card-body::after, .card-2 .ant-card-body::before {
  content: none;
}

.card-3 {
  border-radius: 24px;
  background-color: #D4D4D4;
}

.card-gray {
  background-color: #FAFAFA;
}

.card-gray-2 {
  background-color: #F0F0F0;
}

.card-4 .ant-card-body {
  padding: 24px;
}

.card-5 {
  max-width: 180px;
  border-radius: 16px;
}
.card-5 .ant-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 12px;
  gap: 8px;
}
.card-5 .ant-card-body::after, .card-5 .ant-card-body::before {
  content: none;
}

.payment-method-card, .payment-method-card-add {
  border: 2px solid;
  height: 184px;
  padding: 32px;
}
.payment-method-card .ant-card-body, .payment-method-card-add .ant-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  height: 100%;
}
.payment-method-card .ant-card-body::after, .payment-method-card .ant-card-body::before, .payment-method-card-add .ant-card-body::after, .payment-method-card-add .ant-card-body::before {
  content: none;
}
.payment-method-card img, .payment-method-card-add img {
  width: 56px;
}

.payment-method-add {
  padding: 48px;
}

@media (max-width: 576px) {
  .payment-method-card {
    height: 264px;
  }
  .payment-method-add {
    padding: 32px 16px 48px;
  }
  .card-2 {
    max-width: 300px;
  }
}
@media (min-width: 577px) and (max-width: 1600px) {
  .card-2 {
    max-width: 250px;
  }
}
.fake-carousel {
  display: flex;
  justify-content: center;
  gap: 4px;
}
.fake-carousel .fake-carousel-box {
  height: 4px;
  width: 16px;
  cursor: pointer;
}

nz-carousel .slick-list {
  height: fit-content !important;
}
nz-carousel .slick-list .slick-track {
  height: fit-content !important;
}
nz-carousel [nz-carousel-content] {
  height: fit-content !important;
}
nz-carousel [nz-carousel-content] img {
  width: 100%;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

input[type=checkbox], .ant-checkbox-inner {
  border-color: var(--color-secondary-text, #4A4A4A);
}

.ant-collapse.menu-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
}
.ant-collapse.menu-collapse > .ant-collapse-item > .ant-collapse-header i {
  padding: 0 !important;
}

.ant-collapse.menu-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #595959 !important;
}

.confirm .ant-descriptions-item-content, .confirm .ant-descriptions-item-label {
  border-right: none !important;
}
.confirm .ant-descriptions-item-content {
  font-weight: 600;
  font-size: 14px;
}
.confirm .ant-descriptions-item-label {
  font-weight: bold;
  font-size: 14px;
}
.confirm .ant-descriptions-view {
  border-right: none !important;
  border-left: none !important;
  border-bottom: 2px solid !important;
}

.ant-drawer-body {
  padding-left: 0;
  padding-right: 0;
}

.header-payments .ant-drawer-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.custom-drawer .ant-drawer-body {
  padding: 0;
}

.custom-drawer-tonus .ant-drawer-body {
  padding: 0;
}

ul[profile-menu] {
  min-width: 335px;
}
ul[profile-menu] li {
  min-height: 35px;
}
ul[profile-menu] li:hover {
  background-color: transparent;
  border-bottom: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.primary-icon-24 {
  font-size: 24px;
  color: var(--color-primary, #FF6319);
}

.secondary-icon-48 {
  font-size: 48px;
  color: var(--color-secondary-text, #4A4A4A);
}

.danger-icon-24 {
  font-size: 24px;
  color: #FF5630;
}

.anticon.ant-notification-notice-icon-info {
  color: #467FF0;
}

nz-list[drawer-list] .ant-list-item {
  text-align: center;
}
nz-list[drawer-list] a, nz-list[drawer-list] .ant-collapse-header {
  font-size: 17px;
}

nz-list[historical] .ant-list-item-meta-description {
  opacity: 0.75;
  font-size: 12px;
}
nz-list[historical] .ant-list-item-meta-title {
  font-size: 16px;
  width: 90%;
  text-align: justify;
}

nz-list[drawer-custom-list] .ant-list-item {
  padding-left: 20px;
}
nz-list[drawer-custom-list] .custom-link {
  height: 61px;
  display: flex;
}
nz-list[drawer-custom-list] a {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1.2em;
}

.resume-modal .ant-modal-content {
  border-radius: 24px;
  overflow: hidden;
}
.resume-modal .ant-modal-body {
  padding: 40px 48px;
}

.upload-modal .ant-modal-body {
  padding: 0;
}

.receipt-payment .ant-modal-body {
  padding: 16px;
}

.loading-modal .ant-modal-content {
  border-radius: 24px;
  overflow: hidden;
}
.loading-modal .ant-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.cybersource-method-modal .ant-modal-body {
  padding: 0;
}
.cybersource-method-modal iframe {
  width: 100%;
  height: 720px;
  border: 0;
}

.schedule-info-modal .ant-modal-body {
  max-height: 85vh;
  overflow: auto;
}

.ant-progress-text {
  font-size: 24px;
  display: grid;
}
.ant-progress-text .description {
  font-size: 14px;
}

.detail .ant-radio {
  display: none;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--feedback-success, #1DC663);
}
.ant-radio-checked .ant-radio-inner::after {
  background-color: var(--feedback-success, #1DC663);
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--feedback-success, #1DC663);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: var(--feedback-success, #1DC663);
}

.fee-price-radio {
  margin: 8px;
  width: 100px;
}

.big-radio {
  margin-right: 0;
}
.big-radio .ant-radio-inner {
  width: 24px;
  height: 24px;
}
.big-radio .ant-radio-inner::after {
  transform: scale(1.1);
}

.vertical-radio [nz-radio] {
  display: block;
}

.reversed-radio {
  width: 100%;
}
.reversed-radio [nz-radio] {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 8px 0;
  border-bottom: 1px solid #F0F0F0;
}
.reversed-radio [nz-radio]::after {
  content: none;
}

nz-content nz-spin {
  height: 100%;
}
nz-content nz-spin .ant-spin-container {
  height: 100%;
}

.ant-spin-nested-loading > div > .ant-spin {
  padding: 100px 0;
}

.nopadding-spin .ant-spin {
  padding: 0 !important;
}

.total-spin .ant-spin.ant-spin-spinning {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: inherit;
  min-height: calc(100vh - 134px);
  max-height: fit-content;
}

.spin-body .ant-spin-text {
  font-weight: 400;
  font-size: 14px;
}

.custom-spin .ant-spin {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  height: 160px !important;
}

.ant-steps-item-icon {
  margin: 0;
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
  width: 40px;
  height: 40px;
  line-height: 38px;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: var(--color-tertiary, #FF6319);
  border-color: var(--color-tertiary, #FF6319);
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
  border-color: var(--color-tertiary, #FF6319);
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--color-tertiary, #FF6319);
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
  background-color: var(--color-tertiary, #FF6319);
}

.ant-steps-item-wait .ant-steps-item-icon {
  border-color: #868E96;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #868E96;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #868E96;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: var(--color-tertiary, #FF6319);
}

#double-tab .ant-tabs-content {
  margin-bottom: 8px;
}
#double-tab .ant-tabs-nav-list {
  width: 100%;
}
#double-tab .ant-tabs-tab {
  width: 48%;
  margin: 0 !important;
  text-align: center;
}
#double-tab .ant-tabs-bar {
  margin: 0 !important;
}
#double-tab .ant-tabs-tab-btn {
  width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-tabs-tab-btn {
  font-size: 16px;
}

.ant-tag.default {
  border-color: #D9D9D9;
}

div.ant-typography, .ant-typography p {
  margin-bottom: 0;
}

.ant-layout-content {
  min-height: calc(100vh - 70px);
}

.full-width {
  width: 100%;
}

.page-content {
  min-height: calc(100vh - 134px);
  overflow: hidden;
}
.page-content.flex {
  display: flex;
  justify-content: center;
}
.page-content.flex.center {
  align-items: center;
}
.page-content.flex.column {
  flex-direction: column;
}
.page-content.with-padding {
  padding-bottom: 64px;
}

.page-content-register {
  min-height: calc(100vh - 400px);
  overflow: hidden;
}
.page-content-register.flex {
  display: flex;
  justify-content: center;
}
.page-content-register.flex.center {
  align-items: center;
}
.page-content-register.flex.column {
  flex-direction: column;
}

@media (max-width: 576px) {
  .ant-layout-content {
    min-height: calc(100vh - 114px);
  }
  .page-content {
    min-height: calc(100vh - 178px);
  }
}
nz-footer {
  text-align: center;
}

nz-header {
  position: fixed;
  width: 100%;
  z-index: 2;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.access-pages {
  padding: 40px 0;
  background-size: cover !important;
  background-position: center !important;
}

.double-row {
  width: 100%;
  display: flex;
  justify-content: center;
}

.double-image {
  background-size: cover;
  border-radius: 24px;
}

@media (max-width: 576px) {
  .double-row {
    padding: 0 8px;
  }
}
@media (min-width: 577px) {
  .double-row {
    padding: 0 40px;
  }
}
@media (max-width: 992px) {
  .double-image {
    display: none;
  }
  .double-data {
    max-width: 100%;
  }
}
@media (min-width: 993px) {
  .double-row {
    padding: 0 80px;
    gap: 40px;
  }
  .double-row .double-image, .double-row .double-data {
    flex: 1;
    max-width: 50%;
  }
  .access-pages {
    background: #F8F9FA !important;
  }
}
.pricing {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.featured-product {
  color: #FFF;
  position: absolute;
  top: 16px;
  left: -8px;
  border-style: solid;
  border-width: 16px 10px 16px 0;
  line-height: 0;
  min-width: 150px;
  padding: 0px 16px;
  text-transform: uppercase;
}

.product-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.product-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 10px;
  white-space: break-spaces;
}

.extended .product-description {
  -webkit-line-clamp: 4;
}

.product-card-footer-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.select-price {
  padding-bottom: 0 !important;
}

.fee-image {
  height: 420px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 576px) {
  .product-description {
    -webkit-line-clamp: 3;
  }
}
.custom-collapse .ant-collapse-header {
  color: #595959 !important;
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
  font-weight: 500;
}
.custom-collapse .ant-collapse-content {
  color: #595959 !important;
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
  font-weight: 400;
}

.seats-row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(var(--number-columns, 5), minmax(40px, 1fr));
  grid-row-gap: 1em;
  grid-column-gap: 0.5em;
  overflow: auto;
  padding-bottom: 8px;
  padding-top: 8px;
}
.seats-row .seat-button {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}
.seats-row .seat-button.free {
  border-color: #BFBFBF;
  color: #BFBFBF;
}
.seats-row .seat-button.your {
  border-color: #8C8C8C;
  background-color: #BFBFBF;
}
.seats-row .seat-button.unavailable {
  border-color: #D9D9D9;
  background-color: #D9D9D9;
}
.seats-row .seat-button.selected {
  border-color: var(--color-primary, #FF6319);
  background-color: var(--color-primary-contrast, #FF6319);
  color: var(--color-primary, #FF6319);
}
.seats-row app-public-profile-avatar {
  justify-self: center;
}

.caption-data {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #595959;
  margin-left: 16px;
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}
.caption-data .circle {
  width: 16px;
  height: 16px;
  border: 1px solid;
  border-radius: 50%;
}
.caption-data .circle.free {
  border-color: #BFBFBF;
}
.caption-data .circle.unavailable {
  border-color: #D9D9D9;
  background-color: #D9D9D9;
}

.public_profile_container {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
.public_profile_container .public_avatar {
  padding: 4px;
}
.public_profile_container .public_avatar.info nz-avatar {
  cursor: pointer;
}
.public_profile_container .public_avatar.selected {
  background-color: #FFE8D9;
  border-radius: 8px;
}

.selector-filter-activities .ant-select-selector {
  height: 40px;
  border-radius: 8px !important;
}

.border-radius-common {
  border-radius: 8px;
}

@media print {
  body * {
    visibility: hidden;
  }
  #printable, #printable * {
    visibility: visible;
  }
  .ant-modal {
    top: 0;
    width: auto;
    margin-left: 0;
  }
  button {
    display: none !important;
  }
  .hidden-date {
    display: block !important;
  }
}
/*
    --font-family-bold
    --font-family-regular
    --font-family-medium
    --font-family-light
*/
[family-bold],
.section-title,
.page-title,
.error-title,
.error-subtitle,
.dashboard-section-title,
.title-header h1,
.ant-modal-title,
.ant-result-title,
.ant-modal-confirm-title {
  font-family: var(--font-family-bold, "Montserrat-Bold"), sans-serif;
}

[family-regular],
a,
.section-description,
.show-more,
.section-gray-title,
.data-element,
.ant-popover-message-title,
.ant-result-subtitle,
.ant-modal-confirm-content {
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

[family-light],
.error-description,
img,
.ant-tag {
  font-family: var(--font-family-light, "Montserrat-Light"), sans-serif;
}

[family-medium],
.ant-collapse-header,
.confirm-row,
.container-total,
.container-total-next {
  font-family: var(--font-family-medium, "Montserrat-Medium"), sans-serif;
}

a.turn-back, .ant-spin.turn-back {
  font-family: var(--font-family-medium, "Montserrat-Medium"), sans-serif;
}

[nz-button] {
  font-family: var(--font-family-bold, "Montserrat-Bold"), sans-serif;
}

.featured-product {
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

.primary-link {
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

.data-box .section-text {
  font-family: var(--font-family-bold, "Montserrat-Bold"), sans-serif;
}

nz-list[drawer-list] a, nz-list[drawer-list] .ant-collapse-header {
  font-family: var(--font-family-medium, "Montserrat-Medium"), sans-serif;
}

nz-list[historical] .ant-list-item-meta-description {
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}
nz-list[historical] .ant-list-item-meta-title {
  font-family: var(--font-family-medium, "Montserrat-Medium"), sans-serif;
}

.ant-progress-text {
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

.spin-body .ant-spin-text {
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

.ant-tabs-tab-btn {
  font-family: var(--font-family-medium, "Montserrat-Medium"), sans-serif;
}

.ant-layout-footer {
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

.ant-badge-status-text {
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

.ant-tooltip-inner {
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

.ant-form-item-explain-error {
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

input {
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

.radio-button {
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

.ant-select-item-option-content {
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

.ant-alert-message {
  font-family: var(--font-family-medium, "Montserrat-Medium"), sans-serif;
}

.ant-alert-description {
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

nz-select {
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

.heading-display {
  font-size: 72px;
  font-family: var(--font-family-bold, "Montserrat-Bold"), sans-serif;
}

.heading-0, .heading-1, .heading-2, .heading-3, .heading-4 {
  font-family: var(--font-family-bold, "Montserrat-Bold"), sans-serif;
}

.heading-0 {
  font-size: 56px;
  line-height: 64px;
}

.heading-1 {
  font-size: 48px;
  line-height: 56px;
}

.heading-2 {
  font-size: 40px;
  line-height: 48px;
}

.heading-3 {
  font-size: 32px;
  line-height: 40px;
}

.heading-4, .tagline-big {
  font-size: 24px;
  line-height: 24px;
}

.heading-5 {
  font-size: 12px;
  font-weight: 700;
}

.sub-heading-1 {
  font-size: 24px;
  line-height: 24px;
  font-family: var(--font-family-bold, "Montserrat-Bold"), sans-serif;
}

.sub-heading-2 {
  font-size: 18px;
  line-height: 27px;
  font-family: var(--font-family-bold, "Montserrat-Bold"), sans-serif;
}

.common-1 {
  font-size: 16px;
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

.common-2 {
  font-size: 14px;
  font-family: var(--font-family-bold, "Montserrat-Bold"), sans-serif;
}

.common-3 {
  font-size: 14px;
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

.common-4 {
  font-size: 20px;
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

.common-5 {
  font-size: 24px;
  font-family: var(--font-family-bold, "Montserrat-Bold"), sans-serif;
}

.common-6 {
  font-size: 12px;
  font-family: var(--font-family-bold, "Montserrat-Bold"), sans-serif;
}

.common-7 {
  font-size: 12px;
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

.common-label {
  font-size: 16px;
  display: flex;
  gap: 8px;
  font-family: var(--font-family-bold, "Montserrat-Bold"), sans-serif;
}
.common-label.required::after {
  content: " *";
}

.common-body-label {
  font-size: 16px;
  font-family: var(--font-family-bold, "Montserrat-Bold"), sans-serif;
}

.common-caption-1 {
  font-size: 14px;
  line-height: 21px;
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

.common-caption-2 {
  font-size: 14px;
  line-height: 21px;
  font-family: var(--font-family-bold, "Montserrat-Bold"), sans-serif;
}

.common-caption-3 {
  font-size: 12px;
  line-height: 18px;
  font-family: var(--font-family-bold, "Montserrat-Bold"), sans-serif;
}

nz-input-group.card-input-group-prefix .ant-input-group-addon nz-select-item {
  font-size: 16px;
  font-family: var(--font-family-bold, "Montserrat-Bold"), sans-serif;
}

.receipt-text-1 {
  font-size: 16px;
  font-family: var(--font-family-regular, "Montserrat-Regular"), sans-serif;
}

@media (max-width: 576px) {
  .heading-0 {
    font-size: 48px;
  }
  .heading-1 {
    font-size: 40px;
  }
  .heading-2 {
    font-size: 32px;
  }
  .heading-3 {
    font-size: 24px;
    line-height: 32px;
  }
  .heading-4 {
    font-size: 16px;
    line-height: 24px;
  }
  .sub-heading-1 {
    font-size: 20px;
  }
  .sub-heading-2 {
    font-size: 14px;
  }
  .common-label {
    font-size: 14px;
  }
  .common-body-label {
    font-size: 14px;
  }
}
.section-title,
.page-title {
  color: var(--color-primary-text, #000000);
}

.section-description {
  color: var(--color-secondary-text, #4A4A4A);
}

a, .ant-spin {
  color: var(--color-primary, #FF6319);
}
a:hover, a:focus, a:active, .ant-spin:hover, .ant-spin:focus, .ant-spin:active {
  color: var(--color-primary, #FF6319);
}

.sub-heading-1, .sub-heading-2 {
  color: var(--color-secondary-text, #4A4A4A);
}

.ant-btn {
  color: var(--color-secondary-contrast, #FF6319);
  border-color: var(--color-secondary, #FF6319);
}
.ant-btn:hover, .ant-btn:focus {
  border-color: var(--color-primary, #FF6319);
  color: var(--color-primary, #FF6319);
}
.ant-btn:active {
  background-color: var(--color-primary, #FF6319);
  border-color: var(--color-primary, #FF6319);
}

.ant-btn-text {
  font-size: 14px;
  color: var(--color-primary, #FF6319);
  border-color: var(--color-secondary, #FF6319);
}
.ant-btn-text:hover, .ant-btn-text:focus {
  border-color: var(--color-primary, #FF6319);
  color: var(--color-primary, #FF6319);
}
.ant-btn-text:active {
  background-color: var(--color-primary, #FF6319);
  border-color: var(--color-primary, #FF6319);
}

li.ant-menu-submenu.ant-menu-submenu-open.ant-menu-submenu-horizontal.ant-menu-submenu-active.ng-star-inserted {
  color: var(--color-primary, #FF6319);
  border-color: var(--color-primary, #FF6319);
}

ul[profile-menu] li {
  color: var(--color-primary-text, #000000);
}
ul[profile-menu] li:hover {
  color: var(--color-primary, #FF6319);
}

.menu-list li:hover {
  color: var(--color-primary, #FF6319) !important;
}

.ant-btn-primary {
  background: var(--color-primary, #FF6319);
  border-color: var(--color-primary, #FF6319);
  color: var(--color-primary-contrast, #FF6319);
}
.ant-btn-primary:hover, .ant-btn-primary:focus, .ant-btn-primary:active {
  background: var(--color-primary, #FF6319);
  border-color: var(--color-primary, #FF6319);
  color: var(--color-primary-contrast, #FF6319);
}

.ant-radio-button, .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child, .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--color-primary, #FF6319);
  border-color: var(--color-primary, #FF6319);
  color: #FFF;
}
.ant-radio-button:hover, .ant-radio-button:focus, .ant-radio-button:checked, .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child:hover, .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child:focus, .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child:checked, .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover, .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus, .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):checked {
  background: var(--color-primary, #FF6319);
  border-color: var(--color-primary, #FF6319);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #FFF;
}

.ant-radio-button-wrapper:hover {
  color: var(--color-primary, #FF6319);
}

li {
  color: var(--color-primary-text, #000000);
}
li:hover {
  border-bottom-color: var(--color-primary, #FF6319) !important;
}

.ng-bar {
  background-color: var(--color-primary, #FF6319) !important;
}

li.ant-menu-item.ant-menu-item-selected {
  color: var(--color-primary, #FF6319) !important;
  border-bottom-color: var(--color-primary, #FF6319) !important;
}

html {
  --antd-wave-shadow-color: var(--color-primary, $default-primary-color) !important;
}

::selection {
  background: var(--color-primary, #FF6319) !important;
}

.ant-input-affix-wrapper:hover, .ant-input-affix-wrapper:active, .ant-input-affix-wrapper:focus {
  border-color: var(--color-primary, #FF6319);
  box-shadow: 0 0 0 1px var(--color-primary, #FF6319);
}

.ant-input:hover {
  border-color: var(--color-primary, #FF6319);
}

.ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus {
  border-color: var(--color-primary, #FF6319);
}

.ant-input:focus, .ant-input-focused {
  border-color: var(--color-primary, #FF6319);
  box-shadow: 0 0 0 0px var(--color-primary, #FF6319);
}

.ant-input {
  color: var(--color-secondary-text, #4A4A4A);
}
.ant-input::-webkit-input-placeholder {
  color: var(--color-secondary-text, #4A4A4A);
}
.ant-input:-ms-input-placeholder {
  color: var(--color-secondary-text, #4A4A4A);
}
.ant-input::placeholder {
  color: var(--color-secondary-text, #4A4A4A);
  opacity: 0.6;
}

.ant-select:not(.ant-select-customize-input):hover .ant-select-selector {
  border-color: var(--color-primary, #FF6319);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--color-primary, #FF6319);
  box-shadow: 0 0 0 1px var(--color-primary, #FF6319);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-primary, #FF6319);
  border-color: var(--color-primary, #FF6319);
}

.ant-checkbox-checked::after {
  border-color: var(--color-primary, #FF6319);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--color-primary, #FF6319) !important;
}

.ant-collapse-header {
  color: var(--color-primary, #FF6319);
  font-size: 14px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--color-primary, #FF6319) !important;
}

.ant-tabs-ink-bar {
  background: var(--color-primary, #FF6319) !important;
}

.ant-tabs-tab:hover {
  color: var(--color-primary, #FF6319);
}

.separator {
  color: var(--color-primary, #FF6319) !important;
}

p.price {
  border-bottom-color: var(--color-primary, #FF6319) !important;
}

.detail label.ant-radio-wrapper-checked {
  border-color: var(--color-primary, #FF6319) !important;
}

.day-selected, day-selected:focus {
  background-color: var(--color-primary, #FF6319) !important;
  color: #FFF !important;
}

.ant-tag-checkable, .ant-tag-checkable:active {
  background-color: transparent;
  border-color: var(--color-primary, #FF6319);
  color: var(--color-primary, #FF6319);
}

.ant-tag-checkable-checked {
  background-color: var(--color-primary, #FF6319);
  color: #FFF;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: var(--color-primary, #FF6319);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--color-primary, #FF6319);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: var(--color-primary, #FF6319);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: var(--color-primary, #FF6319);
}

.ant-picker-today-btn, .ant-picker-today-btn:hover {
  color: var(--color-primary, #FF6319);
}

.ant-picker-header-view button:hover {
  color: var(--color-primary, #FF6319);
}

.ant-picker:hover, .ant-picker-focused {
  border-color: var(--color-primary, #FF6319);
}

.ant-switch-checked .ant-switch-loading-icon {
  color: var(--color-primary, #FF6319);
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: var(--color-primary, #FF6319);
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: var(--color-primary, #FF6319);
}

.file-area:hover {
  border-color: var(--color-primary, #FF6319) !important;
}

.file-icon i {
  color: var(--color-primary, #FF6319);
}

.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path,
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: var(--color-primary, #FF6319);
}

.featured-product {
  border-color: var(--color-tertiary, #FF6319) transparent var(--color-tertiary, #FF6319) var(--color-tertiary, #FF6319);
}

.fake-carousel-box.selected-carousel {
  background-color: var(--color-primary, #FF6319) !important;
}

.map-item.free {
  border-color: #D9D9D9;
  color: var(--color-primary, #FF6319);
}
.map-item.selected {
  border-color: var(--color-primary, #FF6319);
}
.map-item.your {
  border-color: var(--color-primary, #FF6319);
  background-color: var(--color-primary, #FF6319);
}
.map-item.is-disabled, .map-item.booked {
  background-color: #D9D9D9;
  border-color: #D9D9D9;
}

.map-text {
  color: var(--color-primary, #FF6319);
}
.map-text.selected, .map-text.booked, .map-text.is-disabled {
  color: var(--color-primary-text, #000000);
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: rgba(0, 0, 0, 0.85);
}

.seat-booked {
  border-color: #FF5630;
  background-color: #FF5630;
  color: #FFF;
}

.caption.free {
  border-color: var(--color-primary, #FF6319);
}
.caption.seat {
  background-color: var(--color-primary, #FF6319);
}
.caption.seatBooked {
  background-color: #FF5630;
}
.caption.unavailable {
  background-color: #D9D9D9;
}

.ant-switch-checked {
  background-color: var(--color-primary, #FF6319);
}

.date-list {
  background-color: #F5F5F5;
  color: var(--color-primary, #FF6319);
}

.ant-tag {
  border-color: var(--color-primary, #FF6319);
}

.dashboard-section-title {
  color: var(--color-primary-text, #000000);
}

.data-box {
  background-color: #FFFFFF;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15);
}
.data-box .section-text {
  color: #979797;
}
.data-box.active {
  border-top-color: #00C800;
}
.data-box.canceled {
  border-top-color: #FFA900;
}
.data-box.free-trial {
  border-top-color: #08979C;
}

.custom-menu-profile ul {
  background-color: rgba(36, 23, 23, 0.95);
}
.custom-menu-profile ul li {
  color: rgba(250, 239, 234, 0.6117647059);
  fill: rgba(250, 239, 234, 0.6117647059);
}
.custom-menu-profile ul li:hover {
  color: #FAEFEA !important;
  fill: #FAEFEA;
}

.custom-menu-profile-tonus ul {
  background-color: rgba(0, 0, 0, 0.95);
}
.custom-menu-profile-tonus ul li {
  color: rgba(250, 239, 234, 0.6117647059);
  fill: rgba(250, 239, 234, 0.6117647059);
}
.custom-menu-profile-tonus ul li:hover {
  color: #FAEFEA !important;
  fill: #FAEFEA;
}

.section-gray-title {
  border-bottom-color: #E8E8E8;
  background-color: #FAFAFA;
}

.confirm-row {
  border-bottom-color: #E0E0E0;
}

.container-total .title {
  color: var(--color-tertiary-text, #595959);
}
.container-total .price {
  color: #262626;
}

.container-total-next {
  color: #8C8C8C;
}

.data-element {
  color: var(--color-primary-text, #000000);
}

.ant-alert-info {
  background-color: #e6f7ff;
  border-color: #91d5ff;
}

.ant-alert-info .ant-alert-icon {
  color: #1890FF;
}

.fake-carousel .fake-carousel-box {
  background-color: #F0F0F0;
}

.confirm .ant-descriptions-view {
  border-bottom-color: #D9D9D9 !important;
}

.custom-drawer .ant-drawer-content {
  background-color: rgba(36, 23, 23, 0.95);
}

.custom-drawer-tonus .ant-drawer-content {
  background-color: rgba(0, 0, 0, 0.95);
}

.refunded .ant-list-item-meta-title {
  color: #FF5630;
}

nz-list[drawer-custom-list] .custom-link {
  border-top-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
nz-list[drawer-custom-list] a {
  color: #FAEFEA;
  fill: #FAEFEA;
}

.ant-progress-text {
  color: rgba(0, 0, 0, 0.85);
}

.spin-body .ant-spin-text {
  color: #262626;
}

.page-content, .page-content-register {
  background: #FFF;
}
.page-content.gray-content, .page-content-register.gray-content {
  background: #F8F9FA;
}
.page-content .payment-process, .page-content-register .payment-process {
  padding-top: 32px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-primary, #FF6319);
  outline: 1px solid var(--color-primary, #FF6319);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-primary, #FF6319);
}

.loading {
  color: var(--color-primary, #FF6319);
}

.primary-link {
  color: var(--color-primary, #FF6319);
}

.desc_notifications {
  color: #AAA;
}

.heading-display {
  color: var(--color-secondary, #FF6319);
}

.heading-0, .heading-1, .heading-2, .heading-3, .heading-4, .heading-5 {
  color: var(--color-primary-text, #000000);
}

.common-1, .common-2, .common-3 {
  color: var(--color-primary-text, #000000);
}

.common-label {
  color: var(--color-secondary-text, #4A4A4A);
}

.common-body-label {
  color: var(--color-secondary-text, #4A4A4A);
}

.common-caption-1, .common-caption-2 {
  color: var(--color-secondary-text, #4A4A4A);
}

.common-caption-3 {
  color: var(--color-tertiary-text, #595959);
}

.tagline-big {
  color: #ADB5BD;
}

.text-error {
  color: #FF5630;
}

nz-select-placeholder {
  color: var(--color-secondary-text, #4A4A4A) !important;
}

.card-integration-input {
  border-color: var(--color-secondary-text, #4A4A4A);
}

nz-select.card-input-select nz-select-item {
  color: var(--color-secondary-text, #4A4A4A);
}

nz-input-group.card-input-group-prefix .ant-input-group-addon {
  background-color: var(--color-primary, #FF6319);
}
nz-input-group.card-input-group-prefix .ant-input-group-addon nz-select-item {
  color: var(--color-primary-text, #000000);
}

.seats-row .seat-button.your {
  border-color: var(--color-primary, #FF6319);
  color: var(--color-primary-text, #000000);
  background-color: var(--color-primary, #FF6319);
}

.caption-data .circle.your {
  border-color: var(--color-primary, #FF6319);
  background-color: var(--color-primary, #FF6319);
}
.caption-data .circle.selected {
  border-color: var(--color-primary, #FF6319);
  background-color: var(--color-primary-contrast, #FF6319);
}

.my-avatar-book {
  background-color: var(--color-primary, #FF6319);
  color: var(--color-primary-text, #000000);
}

.my-avatar-border {
  border: 2px solid var(--color-primary, #FF6319);
}

.my-avatar-guest {
  border-color: var(--color-primary, #FF6319);
  color: #262626;
  background-color: #D9D9D9;
}

.no-foto .no-foto-background {
  fill: #F5F5F5;
}
.no-foto .no-foto-circle {
  fill: #D1D1D1;
  fill-opacity: 0.9;
}

.no-foto.avatar-book .no-foto-background {
  fill: var(--color-primary, #FF6319);
  fill-opacity: 0.2;
}
.no-foto.avatar-book .no-foto-circle {
  fill: var(--color-primary, #FF6319);
}