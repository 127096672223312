.fake-carousel {
    display: flex;
    justify-content: center;
    gap: 4px;

    .fake-carousel-box {
        height: 4px;
        width: 16px;
        cursor: pointer;
    }
}

nz-carousel {
    .slick-list {
        height: fit-content !important;

        .slick-track {
            height: fit-content !important;
        }
    }

    [nz-carousel-content] {
        height: fit-content !important;

        img {
            width: 100%;
            -webkit-user-drag: none;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
        }
    }
}