@import '../utilities/variables';

.section-title {
    font-size: 35px;
    line-height: 43px;
}

.section-description {
    font-size: 18px;
    line-height: 30px;
    white-space: pre-wrap;
}

.page-title {
    text-align: center;
    padding: 32px;
    font-size: 30px;
    line-height: 43px;
    margin-bottom: 0;
}

/*
* ERRORS
*/
.error-background {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #FFF;
}

.error-body {
    width: 400px;
}

.error-title {
    color: #A2A2A2;
    font-size: 50px;
    font-weight: bold;
    line-height: 61px;
    text-align: center;
}

.error-subtitle {
    color: #A2A2A2;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    text-transform: none;
    margin-top: 24px;
}

.error-description {
    color: #A2A2A2;
    font-size: 17px;
    font-weight: 300;
    line-height: 25px;
    text-align: center;
    text-transform: none;
}

html, body {
    scroll-padding-top: 64px;
}

a, .ant-spin {
    &.turn-back {
        font-size: 15px;
    }
}

li {
    &:hover {
        border-bottom: 2px solid !important;
    }
}

li.ant-menu-item.ant-menu-item-selected {
    border-bottom: 2px solid !important;
}

.ant-collapse-header {
    font-size: 14px;
}

.separator {
    width: 100%;
    border-bottom: solid 2px;
    position: relative;
    margin: 24px 0px 24px;
}

p.price {
    border-bottom: 3px solid !important;
}

.fake-carousel-box.selected-carousel {
    width: 24px !important;
}

.map-item {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2px;
    cursor: pointer;
    border: 1px solid;

    img {
        width: 20px !important;
    }

    &.is-disabled, &.booked {
        cursor: inherit;
    }
}

.map-text {
    font-size: 14px;
    text-align: center;
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
    text-align: center;
    padding: 16px;
}

.ant-upload {
    padding: 8px;
}

.caption {
    &.free {
        border: 1px solid;
    }
}

.date-list {
    display: flex;
    justify-content: center;
    padding: 16px !important;
    margin-top: 16px;
}

@media (max-width:992px){
    .section-title {
        font-size: 30px;
    }
}

div[text-center] {
    text-align: center;
}

div[text-right] {
    text-align: right;
}

.text-align-right {
    text-align: right;
}

div[top-0] {
    margin-top: 0 !important;
}

div[top-8], button[top-8] {
    margin-top: 8px !important;
}

div[top-16], button[top-16] {
    margin-top: 16px !important;
}

div[top-24], nz-card[top-24] {
    margin-top: 24px !important;
}

div[top-32], button[top-32], nz-card[top-32] {
    margin-top: 32px !important;
}

div[top-40], nz-card[top-40] {
    margin-top: 40px !important;
}

div[top-48] {
    margin-top: 48px;
}

div[top-64] {
    margin-top: 64px;
}

div[bottom-16] {
    margin-bottom: 16px;
}

div[bottom-24] {
    margin-bottom: 24px;
}

div[bottom-48] {
    margin-bottom: 48px;
}

.margin-bottom-8 {
    margin-bottom: 8px;
}

.margin-bottom-16 {
    margin-bottom: 16px;
}

.margin-left-8 {
    margin-left: 8px;
}

.margin-left-16 {
    margin-left: 16px;
}

.margin-right-8 {
    margin-right: 8px;
}

.margin-right-16 {
    margin-left: 16px;
}

.padding-top-0 {
    padding-top: 0 !important;
}

.padding-bottom-0 {
    padding-bottom: 0 !important;
}

.vertical-padding {
    padding-top: 16px;
    padding-bottom: 16px;
}

nz-date-picker#fake-calendar {
    padding: 0;
    border: 0;
    box-shadow: none;
}

#fake-calendar {
    .ant-picker-input.ng-star-inserted, input {
        display: none;
    }
}

.anticon-spin {
    animation: loadingCircle .5s infinite linear;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* TODO: Añadir más colores custom según se vaya viendo. */

.grecaptcha-badge {
    display: none;
}

// Dashboard
.dashboard-section-title {
    font-size: 30px;
    margin-bottom: 0;
    font-weight: bold;
}

.data-box {
    min-height: 225px;

    .section-text {
        padding: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        color: #979797;
        text-align: center;
    }

    .section-description {
        padding: 16px;
        display: flex;
        align-items: center;
    }

    .section-progress {
        display: flex;
        align-items: center;
    }

    .section-progress-mobile {
        display: flex;
        justify-content: center;
    }

    .section-status {
        padding: 16px;
        text-align: right;
    }

    .section-button {
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: space-between;

        button {
            margin-top: 16px;
        }
    }

    &.active {
        border-top: 8px solid;
    }

    &.canceled {
        border-top: 8px solid;
    }

    &.free-trial {
        border-top: 8px solid;
    }
}

.margin-top-16 {
    margin-top: 16px !important;
}

.show-more {
    font-size: 18px;
    text-decoration: underline;
}

.custom-menu-profile {
    margin-top: 16px;

    ul {
        li {
            font-size: 16px;
            font-weight: 700;
            line-height: 1.2em;

            &:hover {
                border-bottom: 0 !important;
            }
        }
    }
}

.custom-menu-profile-tonus {
    margin-top: 16px;

    ul {

        li {
            font-size: 16px;
            font-weight: 700;
            line-height: 1.2em;

            &:hover {
                border-bottom: 0 !important;
            }
        }
    }
}

.section-gray-title {
    padding: 16px;
    border-bottom: 2px solid;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;

    &.gutter {
        padding: 16px 8px;
    }
}

.confirm-row {
    padding: 16px;
    border-bottom: 1px solid;
}

.info-row {
    padding: 16px;
}

.total-inputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-end;
}

.container-total {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    text-align: right;

    .title {
        font-size: 14px;
        flex: 2;
    }

    .price {
        font-size: 20px;
        font-weight: 600;
        width: 120px;
    }
}

.container-total-next {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    text-align: right;

    .title {
        font-size: 12px;
        flex: 2;
    }

    .price {
        font-size: 14px;
        font-weight: 600;
        width: 120px;
        text-align: right;
    }
}

.payment-method {
    display: flex;
    align-items: center;
    gap: 8px;
}

.text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.data-element {
    border: 0;
    box-shadow: none;
    padding: 4px 0;

    .ant-select-selector {
        border: 0 !important;
        padding: 0 !important;
    }
}

.title-header h1 {
    font-size: 30px;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 24px;
}

.sepa-info {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 700;
}

.ant-result-title {
    line-height: 26px;
}

.ant-result-subtitle {
    margin-top: 16px;
}

.custom-loader {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background:
      radial-gradient(farthest-side, var(--color-primary, $default-primary-color) 94%,#0000) top/16px 16px no-repeat,
      conic-gradient(#0000 -15%, var(--color-primary, $default-primary-color));
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 16px),#000 0);
    mask: radial-gradient(farthest-side,#0000 calc(100% - 16px),#000 0);
    animation:s3 1s infinite linear;
  }

@keyframes s3 {
    100%{transform: rotate(1turn)}
}

.adding-coupon {
    input, button {
        height: 56px;
        font-size: 16px;
    }
}

.payment-method-data {
    display: flex;
    align-items: center;
    gap: 8px;
}

.item-options, .item-start-options {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

input.card-input, .ant-input-number.card-input {
    height: 56px;
    font-size: 16px;
    border-radius: 8px;
    margin-top: 16px;
    width: 100%;
    border-color: var(--color-secondary, $default-secondary-color);

    input {
        height: 56px;
        color: var(--color-secondary-text, $default-secondary-text-color);
    }
}

textarea.card-textarea {
    font-size: 16px;
    border-radius: 8px;
    margin-top: 16px;
    width: 100%;
    border-color: var(--color-secondary, $default-secondary-color);

    input {
        height: 56px;
        color: var(--color-secondary-text, $default-secondary-text-color);
    }
}

.card-integration-input {
    border: 0;
    border-bottom: 1px solid;
    box-shadow: none;
    padding: 4px 16px;
    margin-top: 16px;

    .ant-select-selector {
        border: 0 !important;
        padding: 0 !important;
    }
}

nz-input-group.card-input-group, nz-date-picker.card-input-date {
    height: 56px;
    border-radius: 8px;
    margin-top: 16px;
    width: 100%;
    border-color: var(--color-secondary, $default-secondary-color);

    input {
        font-size: 16px;
    }

    .anticon {
        color: var(--color-secondary-text, $default-secondary-text-color);
    }
}

nz-input-group.card-input-group-prefix {
    height: 56px;
    border-radius: 8px;
    margin-top: 16px;
    width: 100%;

    .ant-input-group-addon {
        height: 56px;
        border-radius: 8px 0 0 8px;
    }

    input {
        font-size: 16px;
        height: 56px;
        border-radius: 0 8px 8px 0;
    }
}

nz-select.card-input-select {
    width: 100%;
    height: 56px;
    border-radius: 8px;
    margin-top: 16px;

    nz-select-top-control {
        border-radius: 8px !important;
        height: 56px !important;
        border-color: var(--color-secondary, $default-secondary-color) !important;
    }

    nz-select-item, nz-select-placeholder {
        padding-top: 12px !important;
        font-size: 16px;
    }

    input {
        font-size: 16px;
    }
}

.gray-box {
    height: 32px;
    width: 42px;
    background: #ADB5BD;
}

.horizontal-field {
    display: flex;
    align-items: center;
    gap: 8px;

    .card-integration-input {
        width: 100%;
        margin-top: 0;
    }

    #card-expiry {
        min-width: 85px;
    }

    #card-cvc {
        min-width: 65px;
    }
}

.border-bottom-dark-16 {
    border-bottom: 1px solid rgba(33, 37, 41, 0.16);
}

.failed-icon {
    border: 3px solid #FF5630;
    padding: 8px;
    border-radius: 50%;
}

.booking-icon-with-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media(max-width: 576px) {
    .item-options {
        justify-content: center;
    }

    .item-start-options {
        justify-content: start;
    }

    .horizontal-field {
        gap: 4px;

        .card-integration-input {
            padding: 4px 8px;
        }

        #card-expiry {
            min-width: 70px;
        }

        #card-cvc {
            min-width: 50px;
        }
    }

    .mobile-full {
        width: 100%;
    }


    div[top-24-to-16] {
        margin-top: 16px !important;
    }
}

@media(min-width: 577px) {
    div[top-24-to-16] {
        margin-top: 24px !important;
    }
}

.ant-affix {
    padding: 16px 0;
    background-color: #F8F9FA;
}

body {
    top: 0 !important;
}

.skiptranslate {
    display: none !important;
}

.goog-te-gadget {
    display: var(--show-translations, 'none') !important;
    color: transparent !important;

    > div {
        color: #666 !important;
        background-color: #dbdad9;
        padding: 8px;
    }

    > span {
        display: none;
    }
}

.gtrans {
    position: absolute;
    top: 64px;
    right: 0;
    background-color: transparent;
    border-radius: 4px;
    text-align: center;
}

.selector-transparent {
    .ant-select-selector {
        background-color: transparent !important;
    }
}

.ant-select-arrow {
    color: #595959;
}

.flex {
    display: flex;
    align-items: center;

    &.gap-8 {
        gap: 8px;
    }

    &.justify {
        justify-content: center;
    }
}

.map-grid {
    padding: 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-warning-icon {
    font-size: 21px;
    color: #FAAD14;
}

.map-body {
    max-height: var(--map-max-height, 380px);
    display: grid;
    grid-template-columns: repeat(var(--number-columns-map, 5), auto);
    width: min-content;

    &.desktop {
        height: 240px;
    }
}

.cell {
    width: var(--map-x-size, 54px);
    height: var(--map-y-size, 76px);
}

.no-wrap {
    flex-wrap: nowrap;
}

.no-foto {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
