.confirm {
    .ant-descriptions-item-content, .ant-descriptions-item-label {
        border-right: none !important;
    }

    .ant-descriptions-item-content {
        font-weight: 600;
        font-size: 14px;
    }

    .ant-descriptions-item-label {
        font-weight: bold;
        font-size: 14px;
    }

    .ant-descriptions-view {
        border-right: none !important;
        border-left: none !important;
        border-bottom: 2px solid !important;
    }
}